html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;


  letter-spacing: normal;

  font-family: -apple-system, BlinkMacSystemFont, 'NotoSerif', 'Monserrat' 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 500;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  webkit-text-size-adjust: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    /* font-family: 'Noto Serif Display', serif; */

}

@font-face {
  font-family: "NotoSerif";
  src: local('NotoSerif'),
    url('./fonts/NotoSerifDisplay-VariableFont_wdth\,wght.ttf') format('truetype');
  font-weight: bold;
}