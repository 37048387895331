.container {
    display: flex;
    justify-content: space-around;
    position: relative;
    z-index: 99;
    flex: 0 0 auto;
    font-family: "Montserrat", Arial, sans-serif;
    font-size: 18px;
    font-weight: 600;
    max-width: 1520px;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 60px;

}

.item {
    color: white;
    width: 30%;
    height: 128px;
    background-color: var(--header-color);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;
    -webkit-box-shadow: 4px 4px 8px 3px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 3px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 3px rgba(34, 60, 80, 0.2);
}

.item:nth-child(2) {
    border-left: 1px solid white;
    border-right: 1px solid white;
}

.icon {
    padding: 20px;
}

@media only screen and (max-width: 1160px) {
    .icon {
        padding: 10px;
    }
}

@media only screen and (max-width: 1060px) {
    .item {
        padding: 10px;
        height: 118px;
    }
}

@media only screen and (max-width: 965px) {
    .img {
        width: 30px;
        height: 35px;
        object-fit: contain;
    }

    .icon {
        padding-right: 5px;
    }

    .item {
        flex-direction: column;
        padding: 20px;
    }

    .txt:nth-child(2) {
        text-align: center;
    }
}

@media only screen and (max-width: 845px) {
    .container {
        margin: 30px 0;
        font-weight: 200;
    }

    .icon {
        padding: 0;
        padding-right: 5px;
    }
}

@media only screen and (max-width: 815px) {
    .item {
        flex-direction: column;
        padding: 20px;
    }

    .txt:nth-child(2) {
        text-align: center;
    }
}

@media only screen and (max-width: 565px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .item {
        margin-top: 10px;
        width: 60%;
        border-radius: 20px;
        flex-direction: row;
        padding: 0 10px;
    }

    .icon {
        padding-right: 10px;
    }
}

@media only screen and (max-width: 520px) {
    .item {
        width: 70%;
    }
}

@media only screen and (max-width: 481px) {
    .container {
        z-index: 1;
    }
}

@media only screen and (max-width: 391px) {
    .item {
        height: 90px;
        font-size: 0.85em;
    }
}

@media only screen and (max-width: 336px) {
    .txt {
        font-size: 0.8em;
    }
}