.modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    padding-top: 0px;
    z-index: 2;
}

.formTitle {
    font-size: 25px;
}

.modalActive {
    opacity: 1;
    pointer-events: all;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    padding-top: 0px;
    z-index: 99;
}

.formTitleContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 27px;
    line-height: 30px;
}

.feedbackForm {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.formInput {
    margin-top: 20px;
}

.subtitle {
    text-align: center;
}

.check {
    display: flex;
}

.formButton {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15em;
    height: 2em;
    font-size: 1.2em;
    letter-spacing: 1px;
    color: white;
    background: #f9b233;
    border: 0;
}

.formButton:hover {
    cursor: pointer;
    opacity: 0.7;
}

.feedbackContainer {
    background-color: rgb(255, 255, 255);
    width: 440px;
    padding: 20px 48px 86px 48px;
}

.closeModalContainer {
    width: 95%;
    display: flex;
    justify-content: flex-end;
}

.closeModal {
    display: flex;
    padding-top: 25px;

    border-radius: 50%;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
}

.closeModal:hover {
    cursor: pointer;
    opacity: 0.7;
}

.formContainer {
    padding: 20px
}

@media only screen and (max-width: 625px) {
    .feedbackContainer {
        width: 400px;
    }

    .formTitleContainer {
        font-size: 20px;
    }
}

@media only screen and (max-width: 610px) {
    .formTitle {
        font-size: 20px;
    }
}

@media only screen and (max-width: 550px) {
    .feedbackContainer {
        width: 350px;
    }

}

@media only screen and (max-width: 500px) {
    .feedbackContainer {
        width: 350px;
        padding: 10px 28px 40px 28px;
    }

    .formTitle {
        font-size: 18px;
    }


}

@media only screen and (max-width: 455px) {
    .feedbackContainer {
        width: 300px;
    }

    .formTitleContainer {
        font-size: 15px;
    }

    .formTitle {
        font-size: 15px;
    }
}

@media only screen and (max-width: 415px) {
    .feedbackContainer {
        width: 280px;
    }
}

@media only screen and (max-width: 340px) {
    .feedbackContainer {
        padding: 10px;
    }
}