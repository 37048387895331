.orderSection {
    width: 90%;
    margin: 0 auto;
}

.orderSectionTitle {
    display: flex;
    padding: 20px 0;
    justify-content: center;
    text-align: center;
    color: rgb(57, 56, 56);
    font-size: 20px;
}

.contentOrder {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

@media only screen and (max-width: 1300px) {
    .orderSection {
        width: 95%;
    }
}

@media only screen and (max-width: 480px) {
    .orderSectionTitle {
        width: 80%;
        margin: 0 auto;
        font-size: 22px;
        padding: 20px 0;
        text-align: center;
    }

    .contentOrder {
        width: 97%;
    }

    .categoryContainer {
        width: 90%;
        padding: 0;
        margin: 0 auto;
    }

    .categoryItem {
        font-size: 8px;
    }
}

@media only screen and (max-width: 397px) {
    .orderSectionTitle {
        width: 90%;
    }
}

@media only screen and (max-width: 383px) {
    .contentOrder {
        width: 100%;
    }
}

@media only screen and (max-width: 379px) {
    .contentOrder {
        width: 97%;
    }

    .orderSectionTitle {
        width: 95%;
    }
}

@media only screen and (max-width: 320px) {
    .orderSectionTitle {
        font-size: 20px;
    }
}