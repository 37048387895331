.typeCatalog {
    margin: 0 auto;
    width: 100%;

    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}