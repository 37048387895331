.companyContainer {
    margin: 0 auto;
    width: 80%;
    padding: 20px;
    max-width: 1520px;

}

.valueContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Times New Roman', Times, serif;
}

.title {
    display: flex;
    justify-content: center;
    font-size: 23px;
    padding-top: 10px;
    color: rgb(57, 56, 56);
}

.name {
    padding-top: 20px;
}

.contactInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.price {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 2em;
    font-size: 1.2em;
    letter-spacing: 1px;
    color: white;
    background: #f9b233;
    -webkit-box-shadow: 4px 4px 8px 3px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 3px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 3px rgba(34, 60, 80, 0.2);
}

.price:hover {
    opacity: 0.7;
    cursor: pointer;
}

.value {
    padding: 10px 0;
    font-size: 20px;
    text-align: center;
}

iframe {
    width: 50em;
    height: 40em;
}

.content {
    display: flex;
    justify-content: space-between;
}

.svg {
    width: 40px;
    height: 43px;
    fill: orange;
}

.info {
    width: 400px;
    height: 200px;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    -webkit-box-shadow: 0px 4px 8px -1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 4px 8px -1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 4px 8px -1px rgba(34, 60, 80, 0.2);
}

.titleContainer {
    font-size: 20px;
}

@media only screen and (max-width: 1412px) {
    iframe {
        width: 45em;
    }
}

@media only screen and (max-width: 1328px) {
    iframe {
        width: 40em;
    }
}

@media only screen and (max-width: 1280px) {
    iframe {
        width: 36em;
    }

    .info {
        width: 20em;
        height: 10em;
        padding: 1em;

    }

    .price {
        width: 19em;
    }
}

@media only screen and (max-width: 1110px) {
    .companyContainer {
        width: 90%;
    }

    iframe {
        width: 33em;
    }
}

@media only screen and (max-width: 1030px) {
    .value {
        font-size: 22px;
    }

    .info {
        width: 15em;
        height: 9em;
    }

    .price {
        width: 15em;
    }
}

@media only screen and (max-width: 950px) {
    .content {
        flex-direction: column;
    }

    .contactInfo {
        display: flex;
    }

    .info {
        width: 40%;
        height: 10em;
        margin: 10px
    }

    .price {
        width: 45%;
    }

    .value {
        font-size: 20px;
    }

    .map {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    iframe {
        width: 90%;
    }
}

@media only screen and (max-width: 900px) {
    .info {
        margin: 20px 5px;
    }
}

@media only screen and (max-width: 850px) {
    .companyContainer {
        padding: 20px 0;
    }

    .info {
        padding: 10px;
    }
}

@media only screen and (max-width: 830px) {
    .icon {
        width: 25px;
        height: 25px;
        padding-bottom: 5px;
    }

    .info {
        margin: 20px 4px;
    }
}

@media only screen and (max-width: 815px) {
    .contactInfo {
        flex-direction: column;
        align-items: center;
    }

    .info {
        width: 70%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
    }

    .valueContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60%;
    }

    .name {
        text-align: center;
    }

    .icon {
        padding-bottom: 0;
    }


}

@media only screen and (max-width: 690px) {
    .icon {
        margin-right: 10px;
    }
}

@media only screen and (max-width: 595px) {
    .info {
        width: 80%;
    }
}

@media only screen and (max-width: 555px) {
    iframe {
        height: 30em;
    }

    .svg {
        margin-right: 10px;
    }
}

@media only screen and (max-width: 525px) {
    .img {
        width: 25px;
        height: 25px;
        object-fit: contain;
    }

    .price {
        padding: 10px;
    }

    .valueContainer {
        width: 70%;
    }

    .value {
        font-size: 18px;
    }

    .svg {
        width: 25px;
        height: 25px;
    }
}

@media only screen and (max-width: 500px) {
    .title {
        font-size: 20px;
    }

    .price {
        font-size: 16px;
    }
}

@media only screen and (max-width: 405px) {
    .valueContainer {
        width: 76%;
    }

    iframe {
        height: 25em;
    }
}

@media only screen and (max-width: 355px) {
    .value {
        font-size: 16px;
    }

    .price {
        font-size: 12px;
    }

}

@media only screen and (max-width: 330px) {
    .title {
        font-size: 18px;
    }
}