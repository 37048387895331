.container {
    width: 80%;
    margin: 0 auto;
}

@media only screen and (max-width: 695px) {
    .title {
        font-size: 22px;
    }

    .content {
        font-size: 14px;
    }
}

@media only screen and (max-width: 505px) {

    .title {
        font-size: 18px;
    }

    .content {
        font-size: 10px;
    }
}