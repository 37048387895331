header {
    /* background-color: red; */
    background-color: var(--header-color);
    padding: 20px 0;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: 96%;
    margin: 0 auto;
    position: relative;
    z-index: 99;
    max-width: 1520px;
}

.headerContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    justify-content: space-between;
}

.logo {
    display: flex;

    align-items: center;
    justify-content: center;
    width: 8em;
}

.headerLogo {
    padding-right: 10px;
    background-size: cover;

    width: 40px;
    height: 40px;
    fill: white;
    filter: invert(1);
}

.logo:hover {
    opacity: 0.7;
}

.logo {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: gold;
    font-size: 28px;
    padding: 0 20px;
    font-weight: 500;
}

nav {
    padding-top: 5px;
    margin: 0 0 0 -60px;
    width: 60%;
    display: flex;
    justify-content: space-between;
}

.navItem {
    color: var(--price-color);
    font-size: 1.6em;
    font-weight: 800;
    /* font-family: "Cormorant Garamond", serif; */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.navItem:hover {
    opacity: 0.7;
}

li {
    list-style: none;
    cursor: pointer;
}

.number {
    display: flex;
}

.number-icon {
    width: 24px;
    height: 24px;
}

@media only screen and (max-width: 1180px) {
    .headerContent {
        width: 90%;
    }
}

@media only screen and (max-width: 1020px) {
    .headerContent {
        width: 95%;
    }

    nav {
        width: 68%;
    }

    .logo {
        width: 7.5em;
        font-size: 0.9em;
    }

    .headerLogo {
        width: 35px;
        height: 35px;
    }

    .navItem {
        font-size: 1.4em;
    }
}

@media only screen and (max-width: 992px) {
    nav {
        width: 68%;

        padding-top: 2px;
    }
}

@media only screen and (max-width: 891px) {
    nav {
        width: 70%;
        margin: 0;
        padding-top: 8px;
    }

    .navItem {
        font-size: 1.2em;
    }
}

@media only screen and (max-width: 768px) {
    nav {
        width: 70%;
        padding-top: 17px;
    }

    .logoContainer {
        padding: 0;
    }

    .headerLogo {
        margin-top: -5px;
    }

    .number {
        font-size: 12px;
    }

    .logo {
        margin-top: 5px;
        width: 200px;
        height: 50px;
        font-size: 0.8em;
    }

    header {
        padding: 10px 0;
    }
}

@media only screen and (max-width: 685px) {
    nav {
        width: 78%;
        padding-top: 19px;
    }

    .headerContent {
        width: 97%;
    }

    .headerWrapper {
        width: 98%;
    }
}

@media only screen and (max-width: 650px) {
    nav {
        width: 85%;
        padding-top: 3px;
    }

    .logo {
        width: 180px;
        display: flex;
        align-items: center;
        font-size: 0.7em;
    }

    .navItem {
        font-size: 1.1em;
    }

    .headerContent {
        align-items: center;
    }
}

@media only screen and (max-width: 590px) {


    .headerLogo {
        width: 30px;
        height: 30px;
        padding-right: px;
    }

    .navItem {
        font-size: 1em;
    }

}

@media only screen and (max-width: 559px) {
    .logo {
        font-size: 0.6em;
        width: 150px;
    }

    .navItem {
        font-size: 0.9em;
    }

    header {
        padding: 0px;
    }
}

@media only screen and (max-width: 550px) {
    header {
        width: 100%;
        padding: 5px 0px;
        border-radius: 0px;
    }

    .headerContent {
        width: 90%;
    }

    nav {
        margin: 0;
    }

    .navItem {
        padding: 20px 0;
        font-size: 20px;
        margin-left: -30px;
    }

    .navItem:nth-child(1) {
        margin-top: 50px;
        display: block;
    }

    .menuBtn {
        width: 30px;
        height: 30px;
        position: relative;
        z-index: 4;
        margin-top: 15px;
        overflow: hidden;
    }

    .menuBtn span {
        width: 30px;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: gold;
        transition: all 0.5s;
    }

    .menuBtn span:nth-of-type(2) {
        top: calc(50% - 5px);
    }

    .menuBtn span:nth-of-type(3) {
        top: calc(50% + 5px);
    }

    .menuBtn.active span:nth-of-type(1) {
        display: none;
    }

    .menuBtn.active span:nth-of-type(2) {
        top: 50%;
        transform: translate(-50%, 0%) rotate(45deg);
    }

    .menuBtn.active span:nth-of-type(3) {
        top: 50%;
        transform: translate(-50%, 0%) rotate(-45deg);
    }

    .headerNav {
        z-index: 3;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 15px;
        background: var(--header-color);
        transform: translateX(-100%);
        transition: transform 0.5s;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }

    .headerNav.active {
        transform: translateX(0);
    }

    .headerNav a {
        list-style-type: none;
    }
}