.item {
    margin: 20px 10px;
    width: 250px;
    height: 250px;
    text-align: center;
    padding: 26px 30px 11px 30px;
    -webkit-box-shadow: 0px 4px 8px -1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 4px 8px -1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 4px 8px -1px rgba(34, 60, 80, 0.2);
}

.itemTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1px 1px 1px;
}

.itemTitleContainer:hover {
    cursor: pointer;
}

.itemPicture {
    width: 9em;
    height: 9em;
    object-fit: contain;
    margin-bottom: 10px;
    cursor: pointer;
}

.itemPicture:hover {
    transform: scale(1.01);
}

.itemName {
    color: var(--base-color);
    font-size: 28px;
    font-weight: 600;
    width: 100%;
    /* font-family: "Cormorant Garamond", serif; */
}

.itemPrice {
    color: var(--price-color);
    font-size: 26px;
    text-align: right;
    font-weight: 600;
    padding: 0 0 0 1px;
    font-family: "Cormorant Garamond", serif;
}

@media only screen and (max-width: 1200px) {
    .item {
        width: 350px;
        height: 350px;
    }

    .itemPicture {
        width: 10em;
        height: 10em;
    }

    .itemName {
        font-size: 2.5em;
    }
}

@media only screen and (max-width: 920px) {
    .item {
        width: 300px;
        height: 300px;
    }

    .itemPicture {
        width: 10em;
        height: 10em;
    }

    .itemName {
        font-size: 2em;
    }
}

@media only screen and (max-width: 820px) {
    .item {
        width: 280px;
        height: 280px;
    }

    .itemPicture {
        width: 10em;
        height: 10em;
    }

    .itemName {
        font-size: 2em;
    }
}

@media only screen and (max-width: 768px) {
    .item {
        width: 350px;
        height: 350px;
    }

    .itemPicture {
        width: 12em;
        height: 12em;
    }

    .itemName {
        font-size: 2.5em;
    }
}

@media only screen and (max-width: 495px) {
    .item {
        width: 230px;
        height: 230px;
    }

    .itemPicture {
        width: 10em;
        height: 10em;
    }

    .itemName {
        font-size: 1.6em;
    }
}

@media only screen and (max-width: 430px) {
    .item {
        width: 200px;
        height: 200px;
    }

    .itemPicture {
        width: 8em;
        height: 8em;
    }

    .itemName {
        font-size: 1.2em;
    }
}

@media only screen and (max-width: 380px) {
    .item {
        width: 180px;
        height: 180px;
    }

    .itemPicture {
        width: 7em;
        height: 7em;
    }

    .itemName {
        font-size: 1.2em;
    }
}