:root {
    --main-bg-color: #c19080;
    --main-br-color: #74635e;
    --header-color: #292F35;
    ;
    --base-color: #383838;
    --price-color: #FCBC04;
    --white-color: #ffffff;
}


.wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    /* max-width: 1480px; */
    /* width: 98%; */
    margin: 0 auto;
    min-height: 100vh;
    /* border: 2px solid var(--price-color); */
    /* border-radius: 20px; */
}



a {
    text-decoration: none;
}

.title {
    flex: 1 1 auto;
}

.title-section--title {
    color: var(--main-bg-color);
}

.subtitle-sub {
    color: var(--main-br-color);
}

.title-section--subtitle {
    grid-area: subtitle;
}

@media only screen and (max-width: 1200px) {
    .wrapper {
        max-width: 1200px;
    }
}

@media only screen and (max-width: 992px) {
    .wrapper {
        max-width: 992px;
    }
}

@media only screen and (max-width: 768px) {
    .wrapper {
        max-width: 768px;
    }
}

@media only screen and (max-width: 480px) {
    .wrapper {
        max-width: 480px;
    }
}

@media only screen and (max-width: 320px) {
    .wrapper {
        max-width: 320px;
    }

    h1 {
        font-size: 1em;
    }
}