.orderItem {

    width: 400px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: white;
    -webkit-box-shadow: 0px 4px 8px -1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 4px 8px -1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 4px 8px -1px rgba(34, 60, 80, 0.2);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.logoContainer {
    width: 400px;
    padding: 20px;
}

.itemTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1px 1px 1px;
    background-color: #f9b233;
    width: 100%;
}

.itemTitleContainer:hover {
    cursor: pointer;
}

.itemPicture {
    height: 20em;
    width: 80%;
    padding: 10px;
    object-fit: contain;
    margin-bottom: 10px;
    cursor: pointer;
}

.itemPicture:hover {
    transform: scale(1.01);
}

.itemName {
    color: var(--base-color);
    font-size: 20px;
    font-weight: 600;
    padding: 10px;
    width: 100%;
    font-family: Montserrat, Arial, sans-serif;
}

.infoContainer {
    color: black;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.titlesContainer {
    display: flex;
    width: 80%;
    margin: 0 auto;


}

.title {
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
    width: 50%;
    text-align: left;
    display: flex;
    align-items: center;
}

.description {
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
    width: 50%;
    text-align: left;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 1448px) {
    .logoContainer {
        width: 350px;
    }

    .orderItem {
        width: 350px;
    }
}

@media only screen and (max-width: 1232px) {
    .logoContainer {
        width: 450px;
    }

    .orderItem {
        width: 450px;
    }
}

@media only screen and (max-width: 1032px) {
    .logoContainer {
        width: 500px;
    }

    .orderItem {
        width: 500px;
    }

    .title {
        font-size: 14px;
    }

    .description {
        font-size: 14px;
    }

    .itemPicture {
        width: 70%;
    }
}

@media only screen and (max-width: 665px) {
    .logoContainer {
        width: 450px;
    }

    .orderItem {
        width: 450px;
    }
}

@media only screen and (max-width: 569px) {
    .logoContainer {
        width: 400px;
    }

    .orderItem {
        width: 400px;
    }
}

@media only screen and (max-width: 509px) {
    .logoContainer {
        width: 350px;
    }

    .orderItem {
        width: 350px;
    }

    .itemPicture {
        height: 18em;
    }
}

@media only screen and (max-width: 430px) {
    .logoContainer {
        width: 300px;
    }

    .orderItem {
        width: 300px;
    }

    .itemPicture {
        height: 14em;
    }

    .title {
        font-size: 10px;
    }

    .description {
        font-size: 10px;
    }
}

@media only screen and (max-width: 375px) {
    .logoContainer {
        width: 280px;
    }

    .orderItem {
        width: 280px;
    }
}