.authContainer {
    width: 100%;
    height: 80vh;
}

.contentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.form {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.value {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.label {
    padding: 5px;
    text-align: center;
}