.container {
    margin: 0 auto;
    width: 80%;
    padding: 60px 0;
}

.containerTitle {
    display: flex;
    justify-content: center;
}

.form {
    padding: 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    border: 1px solid;
    width: 40%;
}

.title {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.types {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.addButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: 0 auto;
}

.infoContainer {
    display: flex;
    flex-direction: column;

}

.name {
    padding: 10px;
    text-align: center;
}

input {
    padding: 20px;
}



.deleteButton {
    display: flex;
    justify-content: center;
}

.back {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20em;
    height: 3em;
    border-radius: 1.1em;
    font-size: 0.8em;

    letter-spacing: 1px;
    color: white;
    background: #f9b233;
}