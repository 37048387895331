.sliderContainer {
    position: relative;
    margin-top: -185px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.sliderItem {
    display: flex;
    width: 100%;
    align-items: center;
}

.sliderContent {
    display: flex;
    height: 750px;
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
}

.sliderPic1 {
    position: relative;
    z-index: 10;
    background-image: url("../../../public/assets/53.jpg");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    display: flex;
    justify-content: end;
    align-items: center;
}

.sliderPic2 {
    position: relative;
    z-index: 10;
    background-image: url("../../../public/assets/12323.jpg");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;

    display: flex;
    justify-content: end;
    align-items: center;
}

.sliderPic3 {
    position: relative;
    z-index: 10;
    background-image: url("../../../public/assets/1_scaled.jpg");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;

    display: flex;
    justify-content: end;
    align-items: center;
}

.sliderContentTitle {
    background: rgb(29, 28, 42);
    background: linear-gradient(49deg,
            rgba(29, 28, 42, 1) 0%,
            rgba(38, 38, 67, 1) 49%,
            rgba(52, 63, 112, 1) 100%);
    clip-path: polygon(1% 0, 100% 0%, 100% 100%, 19% 100%);
    display: flex;
    height: 100%;
    width: 45%;
    color: white;
    font-size: 30px;
}

.textContainer {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
    width: 100%;
    max-width: 100%;
}

.title {
    display: flex;
    margin: 0 auto;
    width: 90%;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    font-family: "Montserrat", Arial, sans-serif;
}

.subtitle {
    font-size: 2.2em;
    text-align: right;
}

.littleTitle {
    text-align: right;
}

.img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%);
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17em;
    height: 2em;
    font-size: 1.2em;
    letter-spacing: 1px;
    color: white;
    background: #f9b233;
    font-family: "Times New Roman", Times, serif;
}

.button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.buttonContainer {
    width: 80%;
    margin-top: 6em;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1320px) {
    .subtitle {
        font-size: 2em;
    }
}

@media only screen and (max-width: 1300px) {
    .sliderContentTitle {
        width: 40%;
    }

    .subtitle {
        font-size: 1.8em;
    }
}

@media only screen and (max-width: 1251px) {
    .sliderContent {
        height: 700px;
    }
}

@media only screen and (max-width: 1205px) {
    .subtitle {
        font-size: 1.6em;
    }
}

@media only screen and (max-width: 1150px) {
    .title {
        width: 90%;
    }

    .sliderContent {
        height: 680px;
    }
}

@media only screen and (max-width: 1085px) {
    .sliderContent {
        height: 650px;
    }

    .buttonContainer {
        margin-top: 4.4em;
    }

    .subtitle {
        font-size: 1.4em;
    }
}

@media only screen and (max-width: 1030px) {
    .sliderContent {
        height: 580px;
    }

    .buttonContainer {
        margin-top: 4em;
    }

    .subtitle {
        font-size: 1.35em;
    }

    .littleTitle {
        font-size: 0.8em;
    }

    .button {
        font-size: 1em;
        width: 15.7em;
    }
}

@media only screen and (max-width: 965px) {
    .sliderContent {
        height: 550px;
    }

    .subtitle {
        font-size: 1.2em;
    }
}

@media only screen and (max-width: 855px) {
    .subtitle {
        font-size: 1.1em;
    }

    .title {
        width: 90%;
    }

    .sliderContent {
        height: 500px;
    }

    .buttonContainer {
        margin-top: 2.4em;
    }

    .button {
        font-size: 0.8em;
    }
}

@media only screen and (max-width: 805px) {
    .subtitle {
        font-size: 1em;
    }

    .littleTitle {
        font-size: 0.75em;
    }

    .buttonContainer {
        margin-top: 2.9em;
    }
}

@media only screen and (max-width: 768px) {
    .button {
        font-size: 0.6em;
    }

    .subtitle {
        font-size: 0.9em;
    }

    .littleTitle {
        font-size: 0.6em;
    }

    .title {
        width: 90%;
    }

    .sliderContent {
        height: 450px;
    }
}

@media only screen and (max-width: 685px) {
    .buttonContainer {
        margin-top: 2em;
    }

    .sliderContent {
        height: 420px;
    }
}

@media only screen and (max-width: 660px) {
    .sliderContent {
        height: 410px;
    }

    .subtitle {
        font-size: 0.8em;
    }

    .littleTitle {
        font-size: 0.6em;
    }

    .buttonContainer {
        margin-top: 2em;
    }
}

@media only screen and (max-width: 615px) {
    .title {
        width: 90%;
    }
}

@media only screen and (max-width: 600px) {
    .title {
        width: 90%;
    }

    .sliderContent {
        height: 420px;
    }

    .littleTitle {
        font-size: 0.55em;
    }
}

@media only screen and (max-width: 575px) {
    .title {
        width: 90%;
    }

    .buttonContainer {
        margin-top: 2em;
    }

    .sliderContent {
        height: 400px;
    }

    .subtitle {
        font-size: 0.7em;
    }

    .button {
        font-size: 0.6em;
    }
}

@media only screen and (max-width: 550px) {
    .sliderContainer {
        margin-top: 0px;
    }

    .subtitle {
        font-size: 0.75em;
    }

    .sliderContent {
        height: 320px;
    }

    .title {
        width: 90%;
    }

    .buttonContainer {
        margin-top: 3.5em;
    }

    .button {
        font-size: 0.55em;
    }

    .littleTitle {
        font-size: 0.5em;
    }
}

@media only screen and (max-width: 509px) {
    .subtitle {
        font-size: 0.65em;
    }
}

@media only screen and (max-width: 481px) {
    .sliderContent {
        height: 300px;
    }

    .sliderContainer {
        height: 330px;
    }

    .button {
        font-size: 14px;
    }
}

@media only screen and (max-width: 469px) {
    .title {
        width: 90%;
        max-width: 100%;
    }

    .littleTitle {
        font-size: 0.47em;
    }

    .button {
        font-size: 0.5em;
    }
}

@media only screen and (max-width: 425px) {
    .button {
        font-size: 0.45em;
    }

    .littleTitle {
        font-size: 0.42em;
    }

    .sliderContent {
        height: 280px;
    }

    .sliderContainer {
        height: 300px;
    }
}

@media only screen and (max-width: 410px) {
    .sliderContent {
        height: 260px;
    }

    .sliderContainer {
        height: 280px;
    }

    .subtitle {
        font-size: 0.56em;
    }

    .littleTitle {
        margin-top: 20px;
        font-size: 0.4em;
    }

    .buttonContainer {
        margin-top: 2.5em;
    }

    .button {
        font-size: 0.4em;
    }
}

@media only screen and (max-width: 391px) {
    .littleTitle {
        font-size: 0.35em;
        text-align: center;
    }

    .subtitle {
        font-size: 0.5em;
    }

    .sliderContent {
        height: 210px;
    }

    .sliderContainer {
        height: 230px;
    }

    .button {
        font-size: 8px;
    }
}