footer {
    width: 100%;
    max-width: 1520px;
    height: 80px;
    background-color: var(--header-color);
    margin-top: auto;
    margin: 0 auto;
}

.footerItem {
    -webkit-tap-highlight-color: var(--white-color);
}

.footerContent {
    height: 80px;
    margin: 0 auto;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(--white-color);
}

.sendMe {
    padding-top: 20px;
}

.socialContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footerIcon {
    padding: 0px 10px;
}

.footerIconPic {
    width: 24px;
    height: 24px;
}

.footerIconPic:hover {
    opacity: 0.7;
}

@media only screen and (max-width: 735px) {
    .footerContent {
        width: 90%;
    }
}

@media only screen and (max-width: 645px) {
    .footerContent {
        width: 95%;
    }
}

@media only screen and (max-width: 585px) {
    .footerRight {
        width: 45%;
        text-align: justify;
    }
}

@media only screen and (max-width: 480px) {
    footer {
        height: 65px;
    }
}

@media only screen and (max-width: 458px) {
    .footerRight {
        width: 50%;
    }

    .footerLeft {
        width: 40%;
        text-align: justify;
    }
}

@media only screen and (max-width: 415px) {
    footer {
        font-size: 10px;
    }
}