.container {
    display: flex;
    justify-content: space-around;
    position: relative;
    z-index: 99;
    flex: 0 0 auto;
    font-family: "Montserrat", Arial, sans-serif;
    font-size: 18px;
    font-weight: 600;

    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
    margin-top: 20px;
}

.item {
    /* font-family: "Noto Serif Display", serif; */
    width: 32%;
    height: 10em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;
    -webkit-box-shadow: 4px 4px 8px 3px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 3px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 3px rgba(34, 60, 80, 0.2);
}

.icon {
    width: 80px;
    height: 80px;
    fill: #1c1b17;
    padding-right: 30px;
}

@media only screen and (max-width: 1005px) {
    .icon {
        padding-right: 5px;
        width: 60px;
        height: 60px;
    }
}

@media only screen and (max-width: 895px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .item {
        width: 60%;
        margin-top: 20px;
    }

    .txt {
        font-size: 1.2em;
    }

    .icon {
        padding: 20px;
    }

}

@media only screen and (max-width: 755px) {
    .txt {
        font-size: 1.2em;
    }
}

@media only screen and (max-width: 655px) {
    .item {
        height: 8em;
    }

    .txt {
        font-size: 1.1em;
    }

}

@media only screen and (max-width: 585px) {
    .item {
        height: 6em;
    }

    .txt {
        font-size: 1em;
    }
}

@media only screen and (max-width: 509px) {
    .icon {
        width: 40px;
        height: 40px;
    }

    .txt {
        font-size: 1em;
    }

    .container {
        margin-top: 0px;
    }
}

@media only screen and (max-width: 481px) {
    .container {
        z-index: 1;
    }

    .txt {
        font-size: 0.9em;
    }
}

@media only screen and (max-width: 440px) {

    .txt {
        font-size: 0.86em;
    }

    .item {
        height: 5em;
    }
}

@media only screen and (max-width: 391px) {
    .txt {
        font-size: 0.8em;
    }
}

@media only screen and (max-width: 351px) {
    .txt {
        font-size: 0.7em;
    }
}