.adminPanel {
    margin: 0 auto;
    width: 80%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.buttonPanel {
    display: flex;
    flex-direction: row;
}

.buttonPanel:nth-child(2) {
    padding-top: 3em;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    height: 50px;
    padding: 10px;
    width: 15em;
    height: 3em;
    border-radius: 1.1em;
    font-size: 0.96em;
    -webkit-box-shadow: 0px 4px 8px -1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 4px 8px -1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 4px 8px -1px rgba(34, 60, 80, 0.2);
    letter-spacing: 1px;
    color: white;
    background: #f9b233;
}

.button:hover {
    cursor: pointer;
    opacity: 0.9;
}