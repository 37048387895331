.container {
    margin: 0 auto;
    width: 80%;
    padding: 60px 0;
}

.containerTitle {
    display: flex;
    justify-content: center;
}

.form {
    padding: 20px;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    border: 1px solid;
    width: 80%;
}

.title {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.types {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.typesSelect {
    height: 50px;
}

.nameContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.informationContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 80%;
}

.addButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: 0 auto;
    padding: 10px;
}

.create {
    padding: 10px;
    margin-top: 10px;
}

.infoContainer {
    display: flex;
    flex-direction: row;
    padding: 20px;

}

.name {
    padding: 10px;
    text-align: center;
}

input {
    padding: 20px 60px;
    width: 80%;
}



.deleteButton {
    display: flex;
    justify-content: center;
}

.back {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20em;
    height: 3em;
    border-radius: 1.1em;
    font-size: 0.8em;

    letter-spacing: 1px;
    color: white;
    background: #f9b233;
}

@media only screen and (max-width: 586px) {
    .infoContainer {
        padding: 20px 10px;
    }

    .form {
        width: 90%;
    }
}

@media only screen and (max-width: 444px) {}