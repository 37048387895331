.headerWidget {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    position: relative;
    z-index: 99;
    height: 40px;
}

.iconBox {
    color: var(--white-color);
    font-family: "Montserrat", Arial, sans-serif;
    display: flex;
    flex-direction: row;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
}

.widgetIconBox {
    display: flex;
    font-weight: 600;
    justify-content: space-around;
    color: #fff;
    align-items: baseline;
}

.iconBox span {
    font-size: 20px;
    font-weight: 600;
}

.headphones {
    width: 15px;
    height: 15px;
    fill: #fff;
}

.headphonesBox {
    fill: #fff;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
}


@media only screen and (max-width: 550px) {
    .headerWidget {
        display: none;
    }
}